import React from 'react'
import {Helmet} from "react-helmet"

const HelmetSettings = () => {
  return (
    <div>
      <Helmet>
        <html lang="cs" />
        <title>Mediace</title>
        <meta name="description" content="Nabízíme Vám přes 35 let praxe v oboru stavebnictví, stavební dozor, řešení sporů, komunikace s úřady. Jsme tu pro Vás." />
      </Helmet>
    </div>
  )
}

export default HelmetSettings